#root {
  background-color: black;
  margin: auto;
  min-width: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  background-color: black !important;
  margin: auto;
  font-family: TWK300, TWK250, BasisMedium, BasisOffWhite, BasisRegular,
    ObjectSansRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@font-face {
  font-family: BasisMedium;
  src: url("./assets/fonts//Basis Grotesque Medium/basis-grotesque-medium-pro.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: BasisOffWhite;
  src: url("./assets/fonts//Basis Grotesque Off White/basis-grotesque-off-white-pro.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: BasisRegular;
  src: url("./assets/fonts//Basis Grotesque Regular/basis-grotesque-regular-pro.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: ObjectSansRegular;
  src: url("./assets/fonts/Object Sans - Regular/WEB/ObjectSans-Regular.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TWK250;
  src: url("./assets/fonts/TWK Lausanne 250/Fonts/Web/TWKLausanne-250.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TWK300;
  src: url("./assets/fonts/TWK Lausanne 300/Fonts/Web/TWKLausanne-300.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
